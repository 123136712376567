import { faChartArea } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import PageHeader from "../../Core/PageHeader";
import Panel from "../../Core/Panel";

export default function Credit() {
	return <React.Fragment>
				<PageHeader icon={ faChartArea } title={ "Credit" }  helpItemDescriptor={ "MyZone/Credit" }
							crumbs={[{ Title: "My Zone" }, { Active: true, Title: "Credit" }]}>
				</PageHeader>
				<Panel>
					<iframe src="https://tsys.co.za:450/Anywhere/#vfs://Global/Facility%20Overview.xview" title="Data" style={{ width: "100%", height: "1000px" }}></iframe>
				</Panel>
			</React.Fragment>
}