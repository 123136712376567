import { View, Text, Font } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../../StyleSheet";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";
import { DateTime } from "luxon";
import { DateFormats } from "../../../../Utilities/Enums";

export default function InterestTransactions(props) {

    Font.registerHyphenationCallback(word => {
        
        const middle = Math.floor(word.length / 2);
        const parts = word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];
    
        return parts;
    });

    return (
        <View style={{ paddingBottom: 10 }}>
            <View style={[styles.tableRowWrapping, { paddingTop: 1, paddingBottom: 1 }]} fixed>
                {
                    props.detailed &&
                    <React.Fragment>
                        <Text style={[styles.textHeaders, { textAlign: 'left', width: '16%', borderTop: 0.5, borderTopColor: 'grey' }]}>Contact Name</Text>
                        <Text style={[styles.textHeaders, { textAlign: 'left', width: '12%', borderTop: 0.5, borderTopColor: 'grey' }]}>Invoice No.</Text>
                    </React.Fragment>
                }
                {
                    !props.detailed &&
                    <React.Fragment>
                        <Text style={[styles.textHeaders, { textAlign: 'left', width: '15%', borderTop: 0.5, borderTopColor: 'grey' }]}>Payment Instruction No.</Text>
                        <Text style={[styles.textHeaders, { textAlign: 'left', width: '7%', borderTop: 0.5, borderTopColor: 'grey' }]}>Run Date</Text>
                        <Text style={[styles.textHeaders, { textAlign: 'left', width: '9%', borderTop: 0.5, borderTopColor: 'grey', paddingLeft: 3 }]}>Status</Text>
                    </React.Fragment>
                }
                <Text style={[styles.textHeaders, { textAlign: 'center', width: ( props.detailed ? '7%' : '6%'), borderTop: 0.5, borderTopColor: 'grey' }]}>Currency</Text>
                <Text style={[styles.textHeaders, { textAlign: 'right', width: ( props.detailed ? '13%' : '12%'), borderTop: 0.5, borderTopColor: 'grey' }]}>Document Total</Text>
                <Text style={[styles.textHeaders, { textAlign: 'right', width: ( props.detailed ? '13%' : '12%'), borderTop: 0.5, borderTopColor: 'grey' }]}>Payment Amount</Text>
                <Text style={[styles.textHeaders, { textAlign: 'right', width: ( props.detailed ? '13%' : '13%'), borderTop: 0.5, borderTopColor: 'grey' }]}>Projected Interest</Text>
                <Text style={[styles.textHeaders, { textAlign: 'right', width: ( props.detailed ? '13%' : '13%'), borderTop: 0.5, borderTopColor: 'grey' }]}>Recovered Interest</Text>
                <Text style={[styles.textHeaders, { textAlign: 'right', width: ( props.detailed ? '13%' : '13%'), borderTop: 0.5, borderTopColor: 'grey' }]}>Outstanding Interest</Text>
            </View>

            {
                props.detailed &&
                props.paymentInstruction.Transactions.length > 0 &&
                props.paymentInstruction.Transactions.map( transaction => 
                    <View style={[styles.tableRowWrapping, { paddingTop: 1, paddingBottom: 1 }]}>
                        {
                            props.detailed &&
                            <React.Fragment>
                                <Text style={[styles.textData, { textAlign: 'left', width: '16%', maxLines:1, textOverflow:'ellipsis' }]}>{ transaction.ContactName }</Text>
                                <Text style={[styles.textData, { textAlign: 'left', width: '12%', maxLines:1, textOverflow:'ellipsis' }]}>{ transaction.InvoiceNumber }</Text>
                            </React.Fragment>
                        }
                        
                        <Text style={[styles.textData, { textAlign: 'center', width: '7%' }]}>{ props.currency }</Text>
                        <Text style={[styles.textData, { textAlign: 'right', width: '13%' }]}>{ formatCurrencyValue(props.currencySymbol, transaction.DocumentTotal) }</Text>
                        <Text style={[styles.textData, { textAlign: 'right', width: '13%' }]}>{ formatCurrencyValue(props.currencySymbol, transaction.PaymentAmount) }</Text>
                        <Text style={[styles.textData, { textAlign: 'right', width: '13%' }]}>{ formatCurrencyValue(props.currencySymbol, transaction.ProjectedInterest) }</Text>
                        <Text style={[styles.textData, { textAlign: 'right', width: '13%' }]}>{ formatCurrencyValue(props.currencySymbol, transaction.RecoveredInterest) }</Text>
                        <Text style={[styles.textData, { textAlign: 'right', width: '13%' }]}>{ formatCurrencyValue(props.currencySymbol, transaction.OutstandingInterest) }</Text>
                    </View>
               )
            }
            {
                props.detailed &&
                props.paymentInstruction.Transactions.length < 1 &&
                <React.Fragment>
                    <Text style={[ styles.textData, {width:'100%', textAlign:'center', padding: 12, color:'#9e9d9d' } ]}>No Transations Available For This Payment Instruction.</Text>
                </React.Fragment>
            }


            {
                !props.detailed &&
                props.paymentInstructions.length > 0 &&
                props.paymentInstructions.map( paymentInstruction =>
                    <View style={[styles.tableRowWrapping, { paddingTop: 1, paddingBottom: 1 }]}>
                        <React.Fragment>
                            <Text style={[styles.textData, { textAlign: 'left', width: '15%', maxLines:1, textOverflow:'ellipsis' }]}>{ paymentInstruction.PaymentInstructionNumber }</Text>
                            <Text style={[styles.textData, { textAlign: 'left', width: '7%' }]}>{ DateTime.fromISO(paymentInstruction.RunDate).toFormat(DateFormats.ShortDate) }</Text>
                            <Text style={[styles.textData, { textAlign: 'left', width: '9%', maxLines:1, textOverflow:'ellipsis', paddingLeft: 3 }]}>{ paymentInstruction.Status }</Text>
                        </React.Fragment>
                        <Text style={[styles.textData, { textAlign: 'center', width: '6%' }]}>{ props.currency }</Text>
                        <Text style={[styles.textData, { textAlign: 'right', width: '12%' }]}>{ formatCurrencyValue(props.currencySymbol, paymentInstruction.DocumentTotal) }</Text>
                        <Text style={[styles.textData, { textAlign: 'right', width: '12%' }]}>{ formatCurrencyValue(props.currencySymbol, paymentInstruction.PaymentAmount) }</Text>
                        <Text style={[styles.textData, { textAlign: 'right', width: '13%' }]}>{ formatCurrencyValue(props.currencySymbol, paymentInstruction.ProjectedInterest) }</Text>
                        <Text style={[styles.textData, { textAlign: 'right', width: '13%' }]}>{ formatCurrencyValue(props.currencySymbol, paymentInstruction.RecoveredInterest) }</Text>
                        <Text style={[styles.textData, { textAlign: 'right', width: '13%' }]}>{ formatCurrencyValue(props.currencySymbol, paymentInstruction.OutstandingInterest) }</Text>
                    </View>
                )
            }
            {
                !props.detailed &&
                props.paymentInstructions.length < 1 &&
                <React.Fragment>
                    <Text style={[ styles.textData, {width:'100%', textAlign:'center', padding: 12, color:'#9e9d9d' } ]}>No Payment Instructions Available For This Facility.</Text>
                </React.Fragment>
            }
            
            <View style={[styles.tableRowWrapping, { paddingTop: 1, paddingBottom: 1 }]}>
                {
                    props.detailed &&
                    props.paymentInstruction.Transactions.length > 0 &&
                    <React.Fragment>
                        <Text style={[styles.textFooters, { textAlign: 'left', width: '16%' }]}>Total</Text>
                        <Text style={[styles.textFooters, { textAlign: 'left', width: '12%' }]}></Text>
                        <Text style={[styles.textFooters, { textAlign: 'center', width: ( props.detailed ? '7%' : '6%') }]}>{ props.currency }</Text>
                        <Text style={[styles.textFooters, { textAlign: 'right', width: ( props.detailed ? '13%' : '12%') }]}>{ formatCurrencyValue(props.currencySymbol, props.paymentInstruction.DocumentTotal) }</Text>
                        <Text style={[styles.textFooters, { textAlign: 'right', width: ( props.detailed ? '13%' : '12%') }]}>{ formatCurrencyValue(props.currencySymbol, props.paymentInstruction.PaymentAmount) }</Text>
                        <Text style={[styles.textFooters, { textAlign: 'right', width: ( props.detailed ? '13%' : '13%') }]}>{ formatCurrencyValue(props.currencySymbol, props.paymentInstruction.ProjectedInterest) }</Text>
                        <Text style={[styles.textFooters, { textAlign: 'right', width: ( props.detailed ? '13%' : '13%') }]}>{ formatCurrencyValue(props.currencySymbol, props.paymentInstruction.RecoveredInterest) }</Text>
                        <Text style={[styles.textFooters, { textAlign: 'right', width: ( props.detailed ? '13%' : '13%') }]}>{ formatCurrencyValue(props.currencySymbol, props.paymentInstruction.OutstandingInterest) }</Text>
                    </React.Fragment>
                }
            </View>


        </View>
    )
}