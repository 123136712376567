import { Text, View, Font } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../../StyleSheet";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";
import { DateTime } from "luxon";
import { DateFormats } from "../../../../Utilities/Enums";

export default function InterestUnderutilisation(props) {
    
    Font.registerHyphenationCallback(word => {
        
        const middle = Math.floor(word.length / 2);
        const parts = word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];
    
        return parts;
    });

    return <React.Fragment>

        <View>
            <Text style={styles.textUnderline}>Underutilsation</Text>
        </View>

        <View style={[styles.tableRowWrapping, { paddingTop: 1, paddingBottom: 1 }]}>
            <Text style={[styles.textHeaders, { textAlign: 'left', width: '16%' }]}>Facility No.</Text>
            <Text style={[styles.textHeaders, { textAlign: 'left', width: '14%' }]}>Advance Date</Text>
            <Text style={[styles.textHeaders, { textAlign: 'center', width: '10%' }]}>Currency</Text>
            <Text style={[styles.textHeaders, { textAlign: 'right', width: '15%' }]}>Current Facility Limit</Text>
            <Text style={[styles.textHeaders, { textAlign: 'right', width: '15%' }]}>Underutilsed Interest</Text>
            <Text style={[styles.textHeaders, { textAlign: 'right', width: '15%' }]}>Recovered Interest</Text>
            <Text style={[styles.textHeaders, { textAlign: 'right', width: '15%' }]}>Outstanding Interest</Text>
        </View>

        {
            props.underutilisations.map( underutilisation =>
                <View style={[styles.tableRowWrapping, { paddingTop: 1, paddingBottom: 1 }]}>
                    <Text style={[styles.textData, { textAlign: 'left', width: '16%', maxLines:1, textOverflow:'ellipsis' }]}> { underutilisation.FacilityNumber }</Text>
                    <Text style={[styles.textData, { textAlign: 'left', width: '14%' }]}> { DateTime.fromISO(underutilisation.AdvanceDate).toFormat(DateFormats.ShortDate) } </Text>
                    <Text style={[styles.textData, { textAlign: 'center', width: '10%' }]}> { underutilisation.Currency } </Text>
                    <Text style={[styles.textData, { textAlign: 'right', width: '15%' }]}> { formatCurrencyValue(underutilisation.CurrencySymbol, underutilisation.CurrentFacilityLimit) } </Text>
                    <Text style={[styles.textData, { textAlign: 'right', width: '15%' }]}> { formatCurrencyValue(underutilisation.CurrencySymbol, underutilisation.UnderutilisedInterest) } </Text>
                    <Text style={[styles.textData, { textAlign: 'right', width: '15%' }]}> { formatCurrencyValue(underutilisation.CurrencySymbol, underutilisation.RecoveredInterest) } </Text>
                    <Text style={[styles.textData, { textAlign: 'right', width: '15%' }]}> { formatCurrencyValue(underutilisation.CurrencySymbol, underutilisation.OutstandingInterest) } </Text>
                </View>
            )
        }

    </React.Fragment>
}