import { Formik, Form as FormikForm } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import DateField from "../../../Core/Forms/DateField";
import { useSessionStore } from "../../../../Stores/SessionStore";
import StructureManager from "../../../../Utilities/StructureManager";
import { StructureDropDown } from "../../../Core/StructureFilter";
import Select, { components } from "react-select";
import SelectField from "../../../Core/Forms/SelectField";
import ReactPDF from "@react-pdf/renderer";
import Interest from "../../Interest";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";

export default function InterestReportModal(props){

    const store = useSessionStore();
	const initialValues = [{ value: -1, label: 'Select all' }]
	const [toDate, setToDate] = useState(null);

    const [groupId, setGroupId] = useState(0);

    
    const [paymentInstructions, setPaymentInstructions] = useState([]);
    const [paymentInstructionOptions, setPaymentInstructionOptions] = useState(initialValues);
    const [paymentInstructionOptionsAll, setPaymentInstructionOptionsAll] = useState([]);
    const [paymentInstructionIds, setPaymentInstructionIds] = useState([]);

    const [facilities, setFacilities] = useState([]);
    const [facilityOptions, setFacilityOptions] = useState(initialValues);
    const [facilityOptionsAll, setFacilityOptionsAll] = useState([]);
    const [facilityIds, setFacilityIds] = useState([]);

    const [detailed, setDetailed] = useState(null);
    const [viewPreferenceOpenClosed, setViewPreferenceOpenClosed] = useState(null);

    const [generatingPreview, setGeneratingPreview] = useState(false);

    const structure = useRef(new StructureManager(store.Session.AuthState.UserAppStructure.AvailableStructure, true));

    useEffect(() => {
        getPaymentInstructions();
    }, [facilities])

    function setGroup(groupId) {
		structure.current.SetSelectedGroup(groupId);
		setGroupId(groupId);

		getFacilities(groupId);
	}

    async function getFacilities(groupId) {

        var result = await store.ReportsService.GetFacilities(groupId);
        if (result && result.Success) {
			var resultData = result.Data;
			setFacilityOptions(initialValues)
			setFacilityOptions(facilityOptions => facilityOptions.concat(resultData.map(item => ({
				value: item.Id,
				label: item.Number + " - " + item.Name
			}))));
			setFacilityOptionsAll(resultData.map(item => ({
				value: item.Id,
				label: item.Number + " - " + item.Name
			})));

			setFacilities([]);
		}

    }

    async function getPaymentInstructions(){

        var results = await store.ReportsService.GetPaymentInstructions(groupId, getFacilitiesIds());
        if (results && results.Success) {
            
			var resultData = results.Data;
            setPaymentInstructionOptions(initialValues);
            setPaymentInstructionOptions( piOptions => piOptions.concat(resultData.map( item => ({
                value: item.Id,
                label: item.piNumber + " - " + item.Name
            }))));
            setPaymentInstructionOptionsAll(resultData.map( item => ({
                value: item.Id,
                label: item.piNumber + " - " + item.Name
            })));

            setPaymentInstructions([]);

		}
    }

    var handleSelectedFacility = (selectedFacilities) => {

		if (selectedFacilities.find(x => x.value === -1)) {
			setFacilities(facilityOptionsAll)
		} else {
			setFacilities(selectedFacilities);
		}

        getPaymentInstructions();

	};

    function getFacilitiesIds() {

		let facilityIds = [];

		for (let i = 0; i < facilities.length; i++) {
			facilityIds.push(facilities[i].value);
		}

		setFacilityIds(facilityIds);

		return facilityIds;

	}

    function getPaymentInstructionIds(){
        
        let paymentInstructionIds = [];

        for(let i=0; i<paymentInstructions.length; i++){
            paymentInstructionIds.push(paymentInstructions[i].value);
        }

        setPaymentInstructionIds(paymentInstructionIds);

        return paymentInstructionIds;

    }

    var handleSelectedPiChanged = (selectedPaymentInstruction) => {

		if (selectedPaymentInstruction.find(x => x.value === -1)) {
			setPaymentInstructions(paymentInstructionOptionsAll)
		} else {
			setPaymentInstructions(selectedPaymentInstruction);
		}

        getPaymentInstructionIds();
	}

    function handleDetails(detailed) {
		if (detailed === "D") {
			setDetailed(true);
		} else if (detailed === "S") {
			setDetailed(false);
		} else {
			setDetailed(null);
		}
	};

	function handleViewPreferenceOpenClosed(openClosed){
		setViewPreferenceOpenClosed(openClosed);
	}

    async function handlePreviewClicked(){

        var filters = {
            ToDate: toDate,
            GroupId: groupId,
            FacilityIds: facilityIds,
            PaymentInstructionIds: getPaymentInstructionIds(),
            Detailed: detailed,
            State: parseInt(viewPreferenceOpenClosed)
        }

        if(generatingPreview === false){
            setGeneratingPreview(true);

            var result = null;
            if(toDate && groupId && facilities && paymentInstructions && detailed !== null && viewPreferenceOpenClosed){

                 result = await store.ReportsService.GetFacilitiesInterestReport(filters);

                if(result && result.Success){
                    const blob = await ReactPDF.pdf(Interest({ data:result.Data, detailed:detailed, state:parseInt(viewPreferenceOpenClosed), closingTotalDate:toDate })).toBlob();
					saveAs(blob, `Interest Report - ${ result.Data.Header.GroupName } ${ detailed ? " Detailed " : " Summary "} ${ viewPreferenceOpenClosed === "0" ? "(All)" : viewPreferenceOpenClosed === "1" ? "(Open)" : "(Closed)"}.pdf`)
                }else{
                    console.log(result.Message)
                }

            }

            setGeneratingPreview(false);
        }

    }

    function onClose() {
        setToDate(null);
        setGroupId(null);
        setGroup("");
        setFacilities([]);
        setPaymentInstructions([]);
        setGeneratingPreview(false);
        setDetailed(null);
        setViewPreferenceOpenClosed(null);
        props.onClose();
    }

    let dateToday = new Date();
	dateToday.setHours(0, 0, 0, 0);

    const CustomDropdownIndicator = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="15"  // Adjust the width here
					height="15" // Adjust the height here
					viewBox="0 0 20 20"
				>
					<path
						d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
						fill="black"
						stroke="white"
					/>
				</svg>
			</components.DropdownIndicator>
		);
	};

    return(
        <React.Fragment>
            <Modal size="lg" enforceFocus={false} show={ props.show }>
                <Formik>
                    <FormikForm>

                        <Modal.Header style={{ backgroundColor: '#38a7df' }}>
                            <h4 style={{ color: 'white', fontWeight: '300' }}>
								{props.Name}
							</h4>
                        </Modal.Header>

                        <Modal.Body>
                            <DateField col={ 9 } className="mb-50" required invalid={ !toDate } label="To" value={ toDate } onChange={ setToDate } minDate={ dateToday }></DateField>
                            <StructureDropDown label="Group" value={structure.current.GroupId} invalid={structure.current.GroupId === ""} structure={structure.current.Groups} onChange={setGroup} required></StructureDropDown>
                            <Row className="mb-50">
								<Col xs={3}>
									<label style={{ marginTop: 10 }}>Facility</label>
								</Col>
								<Col>
									<Select options={facilityOptions} isMulti value={facilities} name="SelectFacility" onChange={handleSelectedFacility} className={facilities.length === 0 ? "form-control is-invalid p-0" : "form-control p-0"} placeholder="---Select---" 
											components={{ DropdownIndicator: CustomDropdownIndicator }} 
											styles={{ control: (baseStyles, state) => ({ ...baseStyles, borderColor: "white", "&:hover": { borderColor: "white" } }), 
											dropdownIndicator: (baseIndicator) => ({ ...baseIndicator, paddingRight: 14 }),
											 placeholder: (basePlaceholder) => ({ ...basePlaceholder, color: "black" }) }}>
									</Select>
								</Col>
							</Row>
                            
                            <Row className="mb-50">
                                <Col xs={3}>
                                    <label style={{ marginTop: 10 }}>Payment Instruction</label>
                                </Col>
                                <Col>
                                <Select options={paymentInstructionOptions} isMulti value={paymentInstructions} name="SelectPaymentInstruction" onChange={handleSelectedPiChanged} className={paymentInstructions.length === 0 ? "form-control is-invalid p-0" : "form-control p-0"} placeholder="---Select---" 
										components={{ DropdownIndicator: CustomDropdownIndicator }} 
										styles={{ control: (baseStyles, state) => ({ ...baseStyles, borderColor: "white", "&:hover": { borderColor: "white" } }), 
										dropdownIndicator: (baseIndicator) => ({ ...baseIndicator, paddingRight: 14 }),
										placeholder: (basePlaceholder) => ({ ...basePlaceholder, color: "black" }) }}>
                                </Select>
                                </Col>
                            </Row>

                            <SelectField label="View Preference 1" required col={9} className="mb-50" invalid={ detailed === null } onChange={e => handleDetails(e.target.value)}>
								<option value="">---Select---</option>
								<option value="D">Detailed</option>
								<option value="S">Summary</option>
							</SelectField>
							<SelectField label="View Preference 2" required col={9} className="mb-50" invalid={ viewPreferenceOpenClosed === "" || viewPreferenceOpenClosed === null } onChange={e => handleViewPreferenceOpenClosed(e.target.value)}>
								<option value="">---Select---</option>
								<option value="0">All</option>
								<option value="1">Open</option>
								<option value="2">Closed</option>
							</SelectField>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button onClick={ handlePreviewClicked } disabled={ generatingPreview } variant="primary">{ generatingPreview ? "Running" :"Run" }</Button>
                            <Button onClick={() => onClose()}>Cancel</Button>
                        </Modal.Footer>

                    </FormikForm>
                </Formik>
            </Modal>
        </React.Fragment>
    )
}